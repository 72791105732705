
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
html {
  font-size: 100%;
}

html,
body {
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: $color-text;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight-regular;
  line-height: $line-height;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: $font-weight-bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}

ol,
ul {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

a,
button {
  margin: 0;
  padding: 0;
  border: 0;
  background: 0;
  cursor: pointer;
}

button,
button:focus,
input,
input:focus,
textarea,
textarea:focus {
  outline: none;
}

a {
  text-decoration: none;
}

// NextJS

#__next {
  height: 100%;
}

// NProgress
#nprogress .bar {
  background: $color-pink-500 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px $color-pink-500, 0 0 5px $color-pink-500 !important;
}

#nprogress .spinner-icon {
  border-top-color: $color-pink-500 !important;
  border-left-color: $color-pink-500 !important;
}
