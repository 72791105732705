
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$components-button-width-small: 38px;
$components-button-height-small: 38px;
$components-button-font-size-small: 0.875rem;

$components-button-width-medium: 46px;
$components-button-height-medium: 46px;
$components-button-font-size-medium: 1rem;

$components-button-width-big: 52px;
$components-button-height-big: 52px;
$components-button-font-size-big: 1.125rem;

// Placeholders

%ecbuttonstyle {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: $components-button-height-medium;
  font-size: $components-button-font-size-medium;
  font-weight: $font-weight-bold;
  cursor: pointer;
  user-select: none;
  transition-property: border-color, background-color, color;
  transition-duration: 0.35s;
  transition-timing-function: ease;
}

%ecbuttongradientbackground {
  overflow: hidden;
  border: unset;
  background: unset;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: block;
    transition: opacity 0.35s ease;
  }

  &:focus,
  &:hover {
    &:after {
      opacity: 0;
    }
  }

  .ecButtonContent {
    z-index: 1;
  }
}

// Component

.ecButton {
  @extend %ecbuttonstyle;
  box-sizing: border-box;
  padding: 0 $space-s;
  border-radius: $border-radius-default;

  &:disabled {
    cursor: default;
    border-color: $color-grey-300;
    background-color: $color-grey-300;
    background-image: none;
    color: $color-white;

    &:after {
      opacity: 0;
    }
  }

  &:focus {
    outline: none;
  }
}

.ecButtonContent {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: $space-xxs;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Has

.ecButtonHasNotification {
  &:after {
    @include animationShowscale;

    content: '';
    position: absolute;
    top: $space-xxxs;
    right: -6px;
    width: 8px;
    height: 8px;
    border: 2px solid $color-white;
    border-radius: 100%;
    background-color: $color-pink-500;
  }
}

// Themes

.ecButtonThemeOutline {
  color: $color-grey-900;
  background-color: $color-white;
  border: $border-default-shorthand;

  &:hover,
  &:focus {
    background-color: $color-grey-200;
  }
}

.ecButtonThemeGhost {
  color: $color-grey-900;
  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    background-color: rgba($color-grey-200, 0.2);
  }

  &:disabled {
    color: $color-grey-900;
    background: none;
    opacity: 0.5;
  }
}

.ecButtonThemeOpacity {
  color: $color-white;
  background-color: rgba($color-grey-800, 0.5);
  border: none;

  &:hover,
  &:focus {
    background-color: rgba($color-grey-800, 0.7);
  }

  &:disabled {
    background-color: rgba($color-grey-800, 0.3);
    color: $color-white;
    opacity: 0.5;
  }
}

.ecButtonThemeWhite {
  color: $color-grey-900;
  background-color: $color-white;

  &:hover,
  &:focus {
    background-color: $color-grey-200;
  }
}

.ecButtonThemePink {
  @extend %ecbuttongradientbackground;
  color: $color-white;
  background-color: $color-pink-700;

  &:after {
    background-image: $color-pink-gradient;
  }
}

.ecButtonThemePurple {
  @extend %ecbuttongradientbackground;
  color: $color-white;
  background-color: $color-purple-700;

  &:after {
    background-image: $color-purple-gradient;
  }
}

.ecButtonThemeGreen {
  @extend %ecbuttongradientbackground;
  color: $color-white;
  background-color: $color-green-500;

  &:hover {
    background-color: $color-green-700;
  }
}

.ecButtonThemeGrey {
  background-color: $color-grey-900;
  color: $color-white;

  &:focus,
  &:hover {
    background-color: $color-grey-700;
  }
}

// Sizes

.ecButtonSizeSmall {
  padding: 0 $space-xs;
  height: $components-button-height-small;
  font-size: $components-button-font-size-small;

  .ecButtonContent {
    gap: $space-xxxs;
  }
}

.ecButtonSizeBig {
  padding: 0 $space-m;
  height: $components-button-height-big;
  font-size: $components-button-font-size-big;
}

// Shapes

.ecButtonShapeCircle {
  flex-basis: $components-button-width-medium;
  width: $components-button-width-medium;
  border-radius: 100%;
  padding: 0;

  &.ecButtonSizeSmall {
    flex-basis: $components-button-width-small;
    width: $components-button-width-small;
  }

  &.ecButtonSizeBig {
    flex-basis: $components-button-width-big;
    width: $components-button-width-big;
  }
}
