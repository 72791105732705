
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ecLoader {
  @extend %flexcenter;
  position: relative;
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  min-height: 76px;
}

.ecLoaderIcon {
  transform-origin: center;
  animation: spin 0.45s infinite linear;
}
