
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecBackButtonLoaderWrapper {
  @extend %flexcenter;
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  overflow: hidden;
  border-radius: 50%;
  background-color: $color-white;
}
