
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecAnchor {
  display: inline;
  transition: opacity 0.2s;
  color: inherit;
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
  }
}

.ecAnchorHasIcon {
  svg {
    width: 1em;
    height: 1em;
    transform: translateY(0.15em);
    margin-right: 0.15em;
  }
}
